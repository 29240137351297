import React from "react";
 
const AboutUs = () => {
    return (
        <div className="privacy-policy-container">
            <h1>About Us</h1>
            <p>Solution:</p>
            <p>Finding stocks which increase in price in future to generate profit is the most important issue that every stock market investor faces. To achieve this, investors need tools for searching and methodologies to ensure the likelihood of future stock growth. Otherwise, not only might they not make a profit from buying stocks, but they could also face losses if the stock price decreases.</p>
            <br></br>
            <p>Stock ranking based on the likelihood of future profitability, Access to an advanced search system with numerous predefined technical analysis criteria, Ability to display and sort stocks based on the highest number of technical buy or sell signals, Ability to display and sort stocks based on the strongest bullish or bearish technical trends, Introduction to trending growth stocks, Ranking of industries and the ranking of companies within each industry, Identification of stocks based on predefined composite trading systems  along with the display of risk and return profiles of these trading systems over various periods, Identification of hot stocks using an expert system, Ability to monitor the investment portfolio and provide exit signals based on technical analysis criteria, Identification of stocks generated by multiple trading systems collectively along with displaying the risk and return profiles of the combined systems if available over various time periods, Ability to display various types of trading pivots, Display of the highest daily - five-day - weekly and monthly returns, Display of the highest daily -weekly - and monthly trading volumes, Display of the largest bullish and bearish gaps across different time frames, Ability to receive selected data in the form of text files for conversion and use in analytical software, all of these tools are powerful for identifying profitable opportunities.</p>
            <br></br>
            <p>Product details:</p>
            <p>The ranking of currencies, commodities and international stock indices reveal the true direction of smart money. Identifying investment opportunities by considering the direction of smart money is the first and most effective step towards successful Trading. Our unique products are a reliable tool for identifying investment opportunities. Now it is the investor’s turn to make a successful trade by analyzing these opportunities, evaluating the risk and reward of each of them and finally taking advantage of the opportunities of his choice.</p>
            <br></br>
            <p>The features of the tools we have designed and developed in our software are applicable to stock, commodity, forex, and cryptocurrency market.</p>
            <br></br>
            <p>2.1  Stock ranking based on the likelihood of future profitability SSI is our unique index for Rank all stocks based on the probability of their rise or fall. Ranking Show the top stocks according to their ascending or descending potential in the future.</p>
            <p>2.2 Access to an advanced search system with numerous predefined technical analysis criteria Screener Provides the ability to search for unique predefined decision components. These components can be set alone or side by side and then displayed according to the priority of the signal strength or their environment or volume in different time frames.</p>
            <p>2.3 Ability to display and sort stocks based on the highest number of technical buy or sell signals in Screener.</p>
            <p>2.4 Ability to display and sort stocks based on the strongest bullish or bearish technical trends in Screener.</p>
            <p>2.5 Introduction to trending growth stocks SST Shows the probability of trending or reversing the trend stocks.</p>
            <p>2.6 Ranking of industries and the ranking of companies within each industry SSV Displays stocks in a frame based on the strength of their future movement.</p>
            <p>2.7 Identification of stocks based on predefined composite trading systems along with the display of risk and return profiles of these trading systems over various periods Trading System Reports signal results from predefined trading systems.</p>
            <p>2.8 Identification of hot stocks using an expert system Hot stock is the page to Add selected stocks in the list with the ability to write text for future review.</p>
            <p>2.9 Ability to monitor the investment portfolio and provide exit signals based on technical analysis criteria Dr Portfolio have Possibility of defining exit indicators and aggregating them to receive a warning to close the trading position.</p>
            <p>2.10 Identification of stocks generated by multiple trading systems collectively along with displaying the risk and return profiles of the combined systems if available over various time periods Multi Systems reports the results of signals from the overlap of predefined trading systems along with their scores.</p>
            <p>2.11 Display of the highest daily, five-day, weekly, and monthly returns Market Watch has ability to sort stocks based on defined market characteristics.</p>
            <p>2.12 Display of the highest daily, weekly, and monthly trading volumes Market Watch has ability to sort stocks based on defined market characteristics.</p>
            <p>2.13 Display of the largest bullish and bearish gaps across different time frames Market Watch has ability to sort stocks based on defined market characteristics.</p>
            <p>2.14 Ability to receive selected data in the form of text files for conversion and use in analytical software Market Watch has ability to sort stocks based on defined market characteristics.</p>
            <p>2.15 Ability to display various types of trading pivots Pivot View various pivot points, supports and resistances in different timeframes.</p>
        </div>
    );
};
 
export default AboutUs;