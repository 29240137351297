import React, { useState } from 'react';
import constant from '../StaticVariables.json';
 
const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
    
      const [responseMessage, setResponseMessage] = useState('');
    
      const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Example POST request to send form data to the backend (you need to set up the backend for sending email)
        try {
          const response = await fetch(`${constant.ApiBaseUrl}Support/contact-email`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
          });
    
          if (response.ok) {
            setResponseMessage('Thank you for contacting us! Your message has been sent.');
          } else {
            setResponseMessage('Oops! Something went wrong, please try again later.');
          }
        } catch (error) {
          console.error('Error sending email:', error);
          setResponseMessage('Error sending email, please try again later.');
        }
    
        // Reset form after submission
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: ''
        });
      };
    
      return (
        <div style={{ maxWidth: '600px', margin: '0 auto' }}>
          <h2>Contact Us</h2>
          <form onSubmit={handleSubmit}>
            <div style={{ marginBottom: '1rem' }}>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                style={{ width: '100%', padding: '0.5rem' }}
              />
            </div>
            <div style={{ marginBottom: '1rem' }}>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                style={{ width: '100%', padding: '0.5rem' }}
              />
            </div>
            <div style={{ marginBottom: '1rem' }}>
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
                style={{ width: '100%', padding: '0.5rem' }}
              />
            </div>
            <div style={{ marginBottom: '1rem' }}>
              <label htmlFor="message">Message</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                style={{ width: '100%', padding: '0.5rem', height: '150px' }}
              />
            </div>
            <button type="submit" style={{ padding: '0.75rem 1.5rem' }}>Send Message</button>
          </form>
    
          {responseMessage && <p>{responseMessage}</p>}
        </div>
      );
    };
 
export default Contact;