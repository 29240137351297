// App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import CaStocksearch from './pages/CaStocksearch';
import UsStocksearch from './pages/UsStocksearch';
import Cryptosearch from './pages/Cryptosearch';
import Contact from './pages/Contact';
import AboutUs from './pages/AboutUs';
import OurTeam from './pages/OurTeam';
import PrivacyPolicy from './pages/PrivacyPolicy';
import './style.css';


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, [pathname]);

  return null; 
};

const App = () => {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/ca-stocksearch" element={<CaStocksearch />} />
          <Route path="/us-stocksearch" element={<UsStocksearch />} />
          <Route path="/crypto-stocksearch" element={<Cryptosearch />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" exact element={<AboutUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/our-team" element={<OurTeam />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
