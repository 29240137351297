// RankingTableWidget.jsx
import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import { ScaleLoader } from 'react-spinners';
import constant from '../StaticVariables.json'

const RankingTableWidget = ({ data, sort, loading, onTrSymbolClick }) => {
  const columns = useMemo(
    () => [
      { Header: `Ranking (${sort})`, accessor: 'ranking' },
      { Header: 'Symbol', accessor: 'symbol' },
      { Header: 'Company Name', accessor: 'companyName' },
      { Header: 'Price', accessor: 'price' },
      { Header: 'Date', accessor: 'date' },
      { Header: 'Chart', accessor: 'tradingViewSymbol' },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  const handleClick = (symbol) => {
    onTrSymbolClick(symbol);
    window.scrollTo(0, 0);
  };

  return (
    <div className="table-container">
      {loading ? (
        <div className="loading-spinner">
          <ScaleLoader color={constant.LoadingColor} loading={loading} height={35} />
        </div>
      ) : (
        <table {...getTableProps()} className={`stock-table ${sort}`}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return cell.column.Header == "Chart" ? 
                      <td style={{textAlign: "center"}}>
                        <button title='TradingView' onClick={() => handleClick(row.original.tradingViewSymbol)}><i style={{textAlign: "center"}} className="fa-solid fa-chart-line"></i></button>
                      </td>
                      :
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default RankingTableWidget;
