import React from "react";
import teamMember1 from '../images/AlirezaMehdipour.jpg';
import teamMember2 from '../images/HosseinAdelzadeh.jpg';
import teamMember3 from '../images/PeimanRazavi.jpg';
import teamMember4 from '../images/MohammadSaket.jpg';
 
const OurTeam = () => {
    return (
        <div>
        <div className="our-team-container">
            <h1>Our Team</h1>
            <p>With 28 years of trading experience in financial markets, Alireza has developed and taught his unique trading style and authored books on technical analysis. He has implemented his innovative formulas and methods by integrating conventional technical analysis formulas into platforms for identifying trading opportunities. He has studies in Industrial Engineering, Entrepreneurship Management, and International Finance.</p>
            <br></br>
            <p>Hossein began his career in financial markets with the Iran Commodity Exchange. Over 22 years of experience, he has held positions such as Trading Floor Supervisor at the Iran Commodity Exchange, Head of Commodity Trading at two prominent brokerage firms, Investment Manager and Portfolio Manager at a bank's investment company, CEO of two brokerage and securities firms, and Chairman of the Board at a portfolio management company. He holds a Bachelor's degree in Industrial Management and a Master's degree in Executive Management.</p>
            <br></br>
            <p>Payman has 14 years of experience in sales, advertising, and marketing. His career includes roles such as Sales Manager, Advertising Manager, Exhibition Sales Manager, CEO, and Head of the Marketing Department. He holds a degree in Mining Engineering and has also completed technical analysis training courses.</p>
            <br></br>
            <p>Mohammad's career began with web designing. With 22 years of experience, he has worked in web designing ,executive production management, project management, and software programming. He holds a degree in Mechanical Engineering and has completed courses in General Management, Systematic Problem-Solving Methods, Project Management, and Comprehensive Technical Analysis. His programming skills include languages such as MQL, Pine Script, Python, Matlab, and Visual Studio.</p>
        </div>

        <div className='section team'>
            <div className='team_container'>
            <h1>MightyAlgo Team</h1>
            <div className='team_members'>

                <div className='team_member'>
                <img className='team_member_image' src={teamMember1} alt='Member 1' />
                <p className='team_member_name'>Alireza Mehdipour</p>
                <small className='team_member_position'>Chief Executive Officer</small>
                </div>

                <div className='team_member'>
                <img className='team_member_image' src={teamMember2} alt='Member 2' />
                <p className='team_member_name'>Hossein Adelzadeh </p>
                <small className='team_member_position'>Chief Operating Officer</small>
                </div>

                <div className='team_member'>
                <img className='team_member_image' src={teamMember3} alt='Member 3' />
                <p className='team_member_name'>Seyed Peiman Razavi </p>
                <small className='team_member_position'>Chief Marketing Officer</small>
                </div>

                <div className='team_member'>
                <img className='team_member_image' src={teamMember4} alt='Member 4' />
                <p className='team_member_name'>Mohammad Saket </p>
                <small className='team_member_position'>IT Specialist</small>
                </div>
            </div>
            </div>
        </div>
    </div>
    );
};
 
export default OurTeam;