// Home.jsx
import React, { useState, useEffect } from 'react';
import constant from '../StaticVariables.json';
import homePic1 from '../images/MightyAlgo_Home1.jpg';
import homePic2 from '../images/MightyAlgo_Home2.jpg';
import homePic3 from '../images/MightyAlgo_Home3.jpg';
import homePic4 from '../images/MightyAlgo_Home4.jpg';
import homePic5 from '../images/MightyAlgo_Home5.jpg';
import homePic6 from '../images/MightyAlgo_Home6.jpg';
import homePic7 from '../images/MightyAlgo_Home7.jpg';
import homePic8 from '../images/MightyAlgo_Home8.jpg';
import teamMember1 from '../images/AlirezaMehdipour.jpg';
import teamMember2 from '../images/HosseinAdelzadeh.jpg';
import teamMember3 from '../images/PeimanRazavi.jpg';
import teamMember4 from '../images/MohammadSaket.jpg';


function Home() {

  useEffect(() => {
    document.title = constant.SiteName + " - Home"
  }, []);

  return (
    <div>
      <div className='section'>
        <div className='container'>
          <div className='content-pair right-image'>
            <div className="content-pair-text jobs">
              <h1>
                Our focus is on producing tools for investors to make right decision and profitable trades in the financial markets
              </h1>
            </div>
            <div className='content-pair-collage'>
              <img width={800} height={548} className='lazyloader' src={homePic1} alt='cover' />
              <div className="bubbles"></div>
            </div>
          </div>
        </div>
      </div>

      <div className='section slanty-section'>
        <div className='slant blue-fade'>
          <div className='container text-white'>
            <div className='content-pair'>
              <div className='content-pair-collage'>
                <img width={800} height={548} className='lazyloader' src={homePic2} alt='scanner' />
                <div className="bubbles"></div>
              </div>
              <div className="content-pair-text jobs">
                <h3>
                  Choosing the right opportunity from more than 100 industries and several thousand companies or choosing from cryptocurrency market is a big challenge. Stock fluctuations in these years highlighted the importance of time and accurate analysis. Short-term, medium-term, and long-term perspectives, on the other hand, required a review of daily, weekly, and monthly charts. Hence the idea of creating software that could search multiple criteria on instruments in different time frames and to be able to depict the situation of a certain share in the shortest possible time with a lot of criteria formed in screener of Mighty algo.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='section'>
        <div className='container'>
          <div className='content-pair right-image'>
            <div className="content-pair-text jobs">
              <h3>
                Another feature of this software is the ranking of financial products in comparison with each other based on completely specific formulas and designed based on more than 800 criteria. This feature is one of the competitive advantages of the <a href='software.so'>software.so</a> we have unique index for ranking .
              </h3>
            </div>
            <div className='content-pair-collage'>
              <img width={800} height={548} className='lazyloader' src={homePic3} alt='ranking' />
              <div className="bubbles"></div>
            </div>
          </div>
        </div>
      </div>

      <div className='section slanty-section'>
        <div className='slant blue-fade'>
          <div className='container text-white'>
            <div className='content-pair'>
              <div className='content-pair-collage'>
                <img width={800} height={548} className='lazyloader' src={homePic4} alt='ssi' />
                <div className="bubbles"></div>
              </div>
              <div className="content-pair-text jobs">
                <h3>
                  Another capability of software includes the possibility of defining custom portfolios for each user and the ability to categorize companies in an industry in a single group as an industry indicator. The purpose of this category is to compare industries and find the best industries for Consequently, finding the most in-demand companies in that industry.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='section'>
        <div className='container'>
          <div className='content-pair right-image'>
            <div className="content-pair-text jobs">
              <h3>
                Another capability of software includes the possibility of defining custom portfolios for each user and the ability to categorize companies in an industry in a single group as an industry indicator. The purpose of this category is to compare industries and find the best industries for Consequently, finding the most in-demand companies in that industry.
              </h3>
            </div>
            <div className='content-pair-collage'>
              <img width={800} height={548} className='lazyloader' src={homePic5} alt='ssv' />
              <div className="bubbles"></div>
            </div>
          </div>
        </div>
      </div>

      <div className='section slanty-section'>
        <div className='slant blue-fade'>
          <div className='container text-white'>
            <div className='content-pair'>
              <div className='content-pair-collage'>
                <img width={800} height={548} className='lazyloader' src={homePic6} alt='screener1' />
                <div className="bubbles"></div>
              </div>
              <div className="content-pair-text jobs">
                <h3>
                  Focusing on a specific instrument is another feature of this software. Suppose you want to review a company in the shortest possible time with a short-term, medium-term, and long-term vision. By entering the company name in the Symbol box in a fraction of a second, all the criteria defined in the software are examined in different time frames and the results are visible. This greatly reduces the role of the trader's emotions in trading and prevents emotional errors by displaying facts.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='section'>
        <div className='container'>
          <div className='content-pair right-image'>
            <div className="content-pair-text jobs">
              <h3>
                Focusing on more than 800 predefined criteria is another software feature and one of its competitive advantages. A number of these criteria are based on a correct understanding of the general indicators of technical analysis and some of them with the optimal combination of these indicators in the form of unique trading strategies and several criteria are based on completely unique formulas based on trading experience. By entering your desired criteria or combination of criteria in the multiple criteria decision-making box All products that have your desired conditions in the target market are displayed. Obviously, it is possible to define trading systems in this box.
              </h3>
            </div>
            <div className='content-pair-collage'>
              <img width={800} height={548} className='lazyloader' src={homePic7} alt='screener2' />
              <div className="bubbles"></div>
            </div>
          </div>
        </div>
      </div>

      <div className='section slanty-section'>
        <div className='slant blue-fade'>
          <div className='container text-white'>
            <div className='content-pair'>
              <div className='content-pair-collage'>
                <img width={800} height={548} className='lazyloader' src={homePic8} alt='algorithmic' />
                <div className="bubbles"></div>
              </div>
              <div className="content-pair-text jobs">
                <h3>
                  Software specific index page, Group comparison page to select the best group and the best product in that group, Display the best ascending and descending rankings, Different pivot screens, Save the user's desired financial product page, Advanced search page with the ability to sort by multiple criteria, Product screens with the possibility of extending the trend or returning from the trend,  Signal display of predefined trading systems with the ability to view risk and rewards in the past, Synchronous signal display of multiple trading systems with the possibility of viewing risk and rewards in the past time periods.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='section team'>
        <div className='team_container'>
          <h1>MightyAlgo Team</h1>
          <div className='team_members'>

            <div className='team_member'>
              <img className='team_member_image' src={teamMember1} alt='Member 1' />
              <p className='team_member_name'>Alireza Mehdipour</p>
              <small className='team_member_position'>Chief Executive Officer</small>
            </div>

            <div className='team_member'>
              <img className='team_member_image' src={teamMember2} alt='Member 2' />
              <p className='team_member_name'>Hossein Adelzadeh </p>
              <small className='team_member_position'>Chief Operating Officer</small>
            </div>

            <div className='team_member'>
              <img className='team_member_image' src={teamMember3} alt='Member 3' />
              <p className='team_member_name'>Seyed Peiman Razavi </p>
              <small className='team_member_position'>Chief Marketing Officer</small>
            </div>

            <div className='team_member'>
              <img className='team_member_image' src={teamMember4} alt='Member 4' />
              <p className='team_member_name'>Mohammad Saket </p>
              <small className='team_member_position'>IT Specialist</small>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}

export default Home;
