// Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/mightyalgo_logo.png'; // Import the logo from the correct path

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="logo">
        <Link to="/" onClick={closeMenu}>
          <img src={logo} alt="Logo" /> {/* Use the imported logo */}
        </Link>
      </div>
      <div className={`menu-toggle ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
      <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
        <li>
          <Link to="/" onClick={closeMenu}>Home</Link>
        </li>
        <li className="dropdown menu-left">
          <Link to="#">
            <span>Services <i className="fas fa-caret-down"></i></span>
          </Link>
          <div className="dropdown-content">
            <Link to="/ca-stocksearch" onClick={closeMenu}>Ca.Stocksearch</Link>
            <Link to="/us-stocksearch" onClick={closeMenu}>Us.Stocksearch</Link>
            <Link to="/crypto-stocksearch" onClick={closeMenu}>Crypto.Stocksearch</Link>
          </div>
        </li>
        <li className="dropdown menu-left">
          <Link to="#">
            <span>Company <i className="fas fa-caret-down"></i></span>
          </Link>
          <div className="dropdown-content">
            <Link to="/about-us" onClick={closeMenu}>About Us</Link>
            <Link to="/our-team" onClick={closeMenu}>Our Team</Link>
            <Link to="/privacy-policy" onClick={closeMenu}>Privacy Policy</Link>
          </div>
        </li>
        <li>
          <Link to="/contact" onClick={closeMenu}>Contact Us</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
