import React, { memo, useState } from "react";
import TradingViewWidget, { Themes } from "react-tradingview-widget";

const TvWidget = ({ symbolValue }) => {
  const [symbol, setSymbol] = useState(symbolValue);
  
  return (
    <TradingViewWidget
      symbol={symbolValue}
      theme={Themes.DARK}
      locale={"en"}
      autosize={true}
      interval={"D"}
      gridColor={"rgba(255, 255, 255, 0.06)"}
      timezone={"Etc/UTC"}
      style={"1"}
      enable_publishing={false}
      withdateranges={true}
      hide_side_toolbar={false}
      allow_symbol_change={true}
      calendar={false}
    />

  );
};

export default memo(TvWidget);