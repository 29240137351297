import React, { useState, useEffect  } from 'react';
import constant from '../StaticVariables.json';
import RankingTableWidget from '../widgets/RankingTableWidget';
import SSVtableWidget from '../widgets/SSVtableWidget';
import SSTtableWidget from '../widgets/SSTtableWidget';
import Cryptoflag from '../images/Crypto-flag.png';
import { Switch } from '@mui/material';

const CryptoTablesComponent = ({ onSymbolClick }) => {

  const [isSwitchOn, setIsSwitchOn] = useState("KU");

  const [cryptoBullishRankingTableData, setCryptoBullishRankingTableData] = useState([]);
  const [cryptoBullishRankingLoading, setCryptoBullishRankingLoading] = useState(true);

  const [cryptoBearishRankingTableData, setCryptoBearishRankingTableData] = useState([]);
  const [cryptoBearishRankingLoading, setCryptoBearishRankingLoading] = useState(true);
  
  const [cryptoSstTableData, setCryptoSstTableData] = useState([]);
  const [cryptoSstLoading, setCryptoSstLoading] = useState(true);

  const handleSwitchChange = async (event) => {
    const newState = event.target.checked;

    const newValue = newState ? 'KU' : 'BI';
    setIsSwitchOn(newValue);
    console.log(newValue);
  };

  useEffect(() => {
  
    const fetchCryptoBullishRankingData = async () => {
      try {
        
        const response = await fetch(`${constant.ApiBaseUrl}Ranking/crypto-bullish?Type=${isSwitchOn}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
  
        setCryptoBullishRankingTableData(data);
        setCryptoBullishRankingLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setCryptoBullishRankingLoading(false);
      }
    };

    const fetchCryptoBearishRankingData = async () => {
      try {
        
        const response = await fetch(`${constant.ApiBaseUrl}Ranking/crypto-bearish?Type=${isSwitchOn}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
  
        setCryptoBearishRankingTableData(data);
        setCryptoBearishRankingLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setCryptoBearishRankingLoading(false);
      }
    };
  
    const fetchCryptoSstData = async () => {
      try {
        
        const response = await fetch(`${constant.ApiBaseUrl}SST/crypto`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
  
        setCryptoSstTableData(data);
        setCryptoSstLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setCryptoSstLoading(false);
      }
    };
  
    fetchCryptoBullishRankingData();
    fetchCryptoBearishRankingData();
    fetchCryptoSstData();
  }, [isSwitchOn]);

    return(
        <div className="widget-card">
        <h4 style={{textAlign: 'center'}}>Crypto<img src={Cryptoflag} style={{maxWidth: '20px'}} /></h4>
        <label>Binance</label>
        <Switch
        defaultChecked 
        onChange={handleSwitchChange}
        inputProps={{ 'aria-label': 'controlled' }} />

        <label>Kucoin</label>

        <p style={{paddingLeft : '1rem', paddingRight: '1rem' }}>In the table below, you can find the top ten bullish ranks of the CryptoCurrency stock market. By clicking the chart icon in the Chart column, the corresponding chart will be displayed in the chart above, which is expected to continue its upward trend in the coming days.</p>
            
        <div style={{overflowX: 'auto'}}>
          <RankingTableWidget data={cryptoBullishRankingTableData} sort={"Bullish"} loading={cryptoBullishRankingLoading} onTrSymbolClick={onSymbolClick.setTrSymbol} />
        </div>
        <br></br>
        <p style={{paddingLeft : '1rem', paddingRight: '1rem' }}>In the table below, you can find the top ten bullish ranks of the CryptoCurrency stock market. By clicking the chart icon in the Chart column, the corresponding chart will be displayed in the chart above, which is expected to continue its upward trend in the coming days.</p>
        <div style={{overflowX: 'auto'}}>
          <RankingTableWidget data={cryptoBearishRankingTableData} sort={"Bearish"} loading={cryptoBearishRankingLoading} onTrSymbolClick={onSymbolClick.setTrSymbol} />
        </div>
        <br></br>
        {/* <div style={{overflowX: 'auto'}}>
          <SSTtableWidget data={cryptoSstTableData} loading={cryptoSstLoading} onTrSymbolClick={onSymbolClick.setTrSymbol} />
        </div> */}

        <h4 style={{textAlign: 'center'}}>For more detail, check <a href='https://www.cryptosearch.trade' target='blank'>cryptosearch.trade</a></h4>
      </div>
    );
}

export default CryptoTablesComponent;