import React, { useState, useEffect  } from 'react';
import { Resizable } from 'react-resizable';
import constant from '../StaticVariables.json';
import TvWidget from '../widgets/TvWidget';
import UsTablesComponent from "../components/UsTablesComponent";

const UsStocksearch = () => {

    const [TradingviewSymbol, setTrSymbol] = useState(constant.TR_USsymbol)
    const [widgetHeight, setWidgetHeight] = useState(500);

    const handleResize = (e, { size }) => {
        setWidgetHeight(size.height);
      };
    
      useEffect(() => {
        document.title = constant.SiteName + " - Us"
     }, []);

     const handleSymbolClick = (symbol) => {
        // Handle click on symbol
        console.log('Symbol clicked:', symbol);
        // Add your custom logic here
    };

    return (
        <div className="home-container">
            <h1>You can find the results of our unique ranking formula!</h1>
            <div className="widget-card">
                <Resizable
                    height={widgetHeight} // Set an initial height
                    handle={<div style={{ height: '10px', background: '#afafaf', cursor: 'ns-resize', textAlign: 'center', fontSize: '8px', borderRadius: '5px', marginTop: '20px' }}><i title='Resize' className="fa-solid fa-up-down" style={{ position: 'absolute' }}></i></div>}
                    onResize={handleResize}>
                    <div className="tradingview-widget" style={{ height: widgetHeight, paddingBottom: '20px' }}>
                        <TvWidget symbolValue={TradingviewSymbol} />
                    </div>
                </Resizable>
            </div>
            <br></br>
            <UsTablesComponent onSymbolClick={{ setTrSymbol }} />
        </div>
    );
};

export default UsStocksearch;