import React, { useState, useEffect  } from 'react';
import constant from '../StaticVariables.json';
import RankingTableWidget from '../widgets/RankingTableWidget';
import SSVtableWidget from '../widgets/SSVtableWidget';
import SSTtableWidget from '../widgets/SSTtableWidget';
import USAflag from '../images/USA-flag.png';

const UsTablesComponent = ({ onSymbolClick }) => {

    const [usaBullishRankingTableData, setUsaBullishRankingTableData] = useState([]);
    const [usaBullishRankingLoading, setUsaBullishRankingLoading] = useState(true);

    const [usaBearishRankingTableData, setUsaBearishRankingTableData] = useState([]);
    const [usaBearishRankingLoading, setUsaBearishRankingLoading] = useState(true);

    const [usaSstTableData, setUsaSstTableData] = useState([]);
    const [usaSstLoading, setUsaSstLoading] = useState(true);

    useEffect(() => {

        const fetchUsaBullishRankingData = async () => {
            try {

                const response = await fetch(`${constant.ApiBaseUrl}Ranking/usa-bullish`);
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();

                setUsaBullishRankingTableData(data);
                setUsaBullishRankingLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setUsaBullishRankingLoading(false);
            }
        };


        const fetchUsaBearishRankingData = async () => {
            try {

                const response = await fetch(`${constant.ApiBaseUrl}Ranking/usa-bearish`);
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();

                setUsaBearishRankingTableData(data);
                setUsaBearishRankingLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setUsaBearishRankingLoading(false);
            }
        };

        const fetchUsaSstData = async () => {
            try {

                const response = await fetch(`${constant.ApiBaseUrl}SST/usa`);
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();

                setUsaSstTableData(data);
                setUsaSstLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setUsaSstLoading(false);
            }
        };

        fetchUsaBullishRankingData();
        fetchUsaBearishRankingData();
        fetchUsaSstData();
    }, []);



    return (
        <div className="widget-card">
            <h4 style={{ textAlign: 'center' }}>USA<img src={USAflag} style={{ maxWidth: '20px' }} /></h4>
            <p style={{paddingLeft : '1rem', paddingRight: '1rem' }}>In the table below, you can find the top ten bullish ranks of the US stock market. By clicking the chart icon in the Chart column, the corresponding chart will be displayed in the chart above, which is expected to continue its upward trend in the coming days.</p>
            <div style={{ overflowX: 'auto' }}>
                <RankingTableWidget data={usaBullishRankingTableData} sort={"Bullish"} loading={usaBullishRankingLoading} onTrSymbolClick={onSymbolClick.setTrSymbol} />
            </div>
            <br></br>
            <p style={{paddingLeft : '1rem', paddingRight: '1rem' }}>In the table below, you can find the top ten bullish ranks of the US stock market. By clicking the chart icon in the Chart column, the corresponding chart will be displayed in the chart above, which is expected to continue its upward trend in the coming days.</p>
            <div style={{ overflowX: 'auto' }}>
                <RankingTableWidget data={usaBearishRankingTableData} sort={"Bearish"} loading={usaBearishRankingLoading} onTrSymbolClick={onSymbolClick.setTrSymbol} />
            </div>
            <br></br>
            {/* <div style={{ overflowX: 'auto' }}>
                <SSTtableWidget data={usaSstTableData} loading={usaSstLoading} onTrSymbolClick={onSymbolClick.setTrSymbol} />
            </div> */}

            <h4 style={{textAlign: 'center'}}>For more detail, check <a href='https://us.stocksearch.trade' target='blank'>us.stocksearch.trade</a></h4>
        </div>
    );
}

export default UsTablesComponent;